import React from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"

import * as S from "../../components/Pages/general"

const ComoDesenharOlhosPage = () => {
  return (
    <Layout>
      <SEO
        title="Como desenhar olhos - tutorial passo a passo"
        description=""
      />
      <S.GeneralWrapper>
        <S.GeneralTitle>Como Desenhar Olhos - Tutorial </S.GeneralTitle>
      </S.GeneralWrapper>
    </Layout>
  )
}

export default ComoDesenharOlhosPage
